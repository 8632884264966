import { getSettings, Settings } from '@/settings';
import { expandAllButLastNGroups } from '@/utils/grid';
import { ColumnRowGroupChangedEvent } from 'ag-grid-community';
import { columnChangedHandler, triggerHeaderHeight } from '..';
import { ExpandedRowGroupsUtil } from '@/utils/ExpandedRowGroups';

/**
 * This function runs when the column row group changes.
 * It has to do the following things
 * 1. Trigger the header height to change
 * 2. Expand all the groups except the last N groups (if settings.autoExpandGroups is true)
 * 3. Remove all expanded row groups stored in expandedRowGroups (in context and gridState) if the column is removed
 */

export function onColumnRowGroupChanged(event: ColumnRowGroupChangedEvent) {
  const settings = getSettings() as Settings;
  let { column, columns } = event;

  if (!column) {
    column = columns?.pop() ?? null;
  }
  if (!column) return;

  const api = event.api;

  // update the parameter (for feature: autoAggregation based on visible columns)
  if (api.isPivotMode()) {
    columnChangedHandler(column.getColId(), column.isRowGroupActive());
  } else {
    columnChangedHandler(column.getColId(), column.isRowGroupActive() || column.isVisible());
  }

  // expand all groups except the last N groups
  if (settings.autoExpandGroups) {
    expandAllButLastNGroups(api, settings.expandAllButLastNGroups);
  } else {
    const isColumnRemoved = !column.isRowGroupActive();
    if (isColumnRemoved) {
      const context = event.context;
      const expandedRowGroups = context?.expandedRowGroups;
      const field = column.getColId();

      ExpandedRowGroupsUtil.removeAllFields(expandedRowGroups, field);
    }
  }

  triggerHeaderHeight(event);
}

import './index.css';
import setupApp from './app';
import { openConfig, checkHostName } from './utils/tableau';
import { showButton, initLandingPage } from './grid/interface';
import type { TableauEventUnregisterFn } from '@tableau/extensions-api-types';
import Cache from '@/utils/cache';
import userMetrics from '@appsfortableau/user-metrics-module';

const extension = window.tableau.extensions;
let dashboardName;
const TableauEventType = window.tableau.TableauEventType;
const cache = Cache.getInstance();

// @ts-ignore: process env gets defined by webpack
function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  console.log('Query variable %s not found', variable);
}

let unregisterHandlerFunctions: TableauEventUnregisterFn[] = [];

try {
  const PRODUCT = import.meta.env.VITE_PRODUCT;

  checkHostName(PRODUCT);

  if (getQueryVariable('load-app')) {
    console.time('initialize dialog');
    tableau.extensions.initializeDialogAsync().then(() => {
      console.timeEnd('initialize dialog');
      setupApp();
    });
  } else {
    console.time('initialize');
    extension
      .initializeAsync({ configure: openConfig })
      .then(function () {
        console.timeEnd('initialize');

        // remove this piece of code if Tableau fixes this in v1.12.1 or v1.13.0.
        // this is a fix to make 1.12 compatible with 2023.x tableau server.
        if (parseFloat(extension?.environment?.tableauVersion) < 2024.2) {
          const dashboard = extension.dashboardContent?.dashboard;
          dashboardName = dashboard?.name;
          dashboard?.addEventListener(window.tableau.TableauEventType.DashboardLayoutChanged, () => {
            console.log('dashboard layout changed, writing:', dashboardName);
            const dashboardContent = window.tableau.extensions.dashboardContent;
            if (
              dashboardContent &&
              dashboardContent.dashboard &&
              dashboardContent.dashboard._dashboardImpl &&
              dashboardContent.dashboard._dashboardImpl._sheetInfoImpl
            ) {
              dashboardContent.dashboard._dashboardImpl._sheetInfoImpl.name = dashboardName;
            }
          });
        }

        // send user metrics to host server
        if (import.meta.env.MODE !== 'development') {
          const isEnterprise = PRODUCT === 'e';
          userMetrics(extension.environment, undefined, isEnterprise);
        }

        initLandingPage(true);
        const MODE = extension.environment.mode;

        if (PRODUCT === 't' && MODE !== 'authoring') return;
        extension.settings.addEventListener(TableauEventType.SettingsChanged, () => initializeApp());

        initializeApp();
      })
      .catch((error) => {
        initLandingPage(false);
      });
  }
} catch (e) {
  console.error('Error initializing app', e);
}

function checkParameterUsedForSwitchingDatasheet(settings) {
  const datasheet = settings.datasheet ?? '';
  const useParameterForDatasheet = JSON.parse(settings?.useParameterForDatasheet ?? 'false');

  // only if datasheet exists (SuperTables is configured) and a parameter is set for datasheet
  return datasheet && useParameterForDatasheet;
}

async function setDatasheetFromParameter(settings) {
  const datasheetParameter = settings.datasheetParameter;

  if (!datasheetParameter) {
    console.error('No parameter set for datasheet');
    return;
  }

  const parameter = await extension.dashboardContent?.dashboard.findParameterAsync(datasheetParameter);
  if (!parameter) {
    console.error(`Parameter ${datasheetParameter} not found to switch datasheet`);
    return;
  }

  const currentDatasheetName = parameter.currentValue.value;

  if (!currentDatasheetName) {
    console.error(`No value set for parameter ${datasheetParameter} to switch datasheet`);
    return;
  }
  cache.set('currentDatasheetFromParameter', currentDatasheetName);
}

export async function initializeApp(reason?: 'multi-layout-switching' | 'datasheet-switching') {
  let settings = extension.settings.getAll();

  if (!extension.worksheetContent && checkParameterUsedForSwitchingDatasheet(settings)) {
    await setDatasheetFromParameter(settings);
  }

  //do the rest only if the origin is dialog
  let shouldPopUp = false;
  if (settings.enablePopUp) {
    shouldPopUp = JSON.parse(settings.enablePopUp);
  }
  if (!shouldPopUp || !!extension.worksheetContent) {
    setupApp(reason);
  } else {
    const dashboard = extension.dashboardContent?.dashboard;
    //TODO: Double check this
    let worksheets: Array<{ [key: string]: any }> = [];
    if (settings.popUpWorksheets) {
      worksheets = JSON.parse(settings.popUpWorksheets);
    }

    // cleanup old eventlisteners
    unregisterHandlerFunctions.forEach(function (unregisterHandlerFunction) {
      unregisterHandlerFunction();
    });

    unregisterHandlerFunctions = [];

    worksheets.forEach((ws) => {
      let worksheet = dashboard?.worksheets.find((sheet) => sheet.name === ws.value);

      let unregisterHandlerFunction = worksheet?.addEventListener(TableauEventType.MarkSelectionChanged, () => {
        worksheet.getSelectedMarksAsync().then(function (marks) {
          marks.data.forEach((worksheetData) => {
            if (worksheetData.totalRowCount > 0) {
              openDialog();
            }
          });
        });
      });
      if (unregisterHandlerFunction) {
        unregisterHandlerFunctions.push(unregisterHandlerFunction);
      }
    });

    // check if we should listen for events, parameter change or button click????
    showButton();
    const container = document.querySelector('#extensionTriggerButton button');

    container?.addEventListener('click', (e) => {
      e.preventDefault();
      openDialog();
    });
  }
  return () => {};
}

function openDialog() {
  let settings = extension.settings.getAll();
  let showPopupOnData = false;
  if (settings.showPopupOnData) {
    showPopupOnData = JSON.parse(settings.showPopupOnData);
  }

  const isVizExtension = !!extension.worksheetContent;

  if (showPopupOnData && !isVizExtension) {
    let datasheet = extension.dashboardContent?.dashboard.worksheets.find((sheet) => sheet.name === settings.datasheet);

    datasheet
      ?.getSummaryDataAsync({
        includeDataValuesOption: settings.onlyNativeValues
          ? tableau.IncludeDataValuesOption.OnlyNativeValues
          : undefined,
      })
      .then(({ data: r }) => {
        if (r.length > 0) {
          displayDialog();
        }
      });
  } else {
    displayDialog();
  }
}

function displayDialog() {
  const popupUrl = document.location.href + '?load-app=1';
  tableau.extensions.ui
    .displayDialogAsync(popupUrl, undefined, {
      width: 900,
      height: 600,
      //@ts-ignore can't seem to import the dailogStyle from the tableau
      dialogStyle: 'window',
    })
    .then((closePayload) => {
      console.log('Successfully closed dialog', closePayload);
      //
      // The promise is resolved when the dialog has been closed as expected, meaning that
      // the popup extension has called tableau.extensions.ui.closeDialog() method.
      // The close payload (closePayload) is returned from the popup extension
      // via the closeDialog() method.
      //
    })
    .catch((error) => {
      // One expected error condition is when the popup is closed by the user (meaning the user
      // clicks the 'X' in the top right of the dialog). This can be checked for like so:
      switch (error.errorCode) {
        case tableau.ErrorCodes.DialogClosedByUser:
          console.log('Dialog was closed by user');
          break;
        default:
          console.error(error.message);
      }
    });
}

import { removeWhiteSpaces } from '@/utils/functional';
import { createBackgroundStyler } from '@/grid/mapping/createStyler';
import { isValueEmpty } from '@/grid/mapping/utils';
import { Settings } from '@/settings';
import { removeAgg } from '@/utils/tableau';
import { CellClassParams, CellStyle, ColDef, GridOptions, HeaderClassParams } from 'ag-grid-community';
import { gridOptions } from '..';
import { getHeaderCellClass } from './styleFunctions';

export const getAutoGroupColumnDef = (gridOptions: GridOptions, settings: Settings): ColDef => {
  let isAuthoring = window.tableau.extensions.environment.mode === 'authoring';
  return {
    field: settings.unbalancedGroupColumn,
    headerTooltip: settings.unbalancedGroupColumn,
    filter: 'agGroupColumnFilter',
    floatingFilter: settings.showFilterBar,
    valueFormatter: (params) => {
      let value = params.value;
      if (params?.type !== 'csv') value = removeWhiteSpaces(value);
      return value;
    },
    tooltipValueGetter: (params) => {
      const count = params.node && params.node.allChildrenCount;
      const groupSuppressCount = settings.groupSuppressCount;
      if (count && !groupSuppressCount) {
        return params.value + ' (' + count + ')';
      }
      return params.value;
    },
    autoHeight: settings.autoRowHeight ? true : false,
    wrapText: settings.autoRowHeight ? true : false,
    suppressMovable: !settings.userCanOrderColumns && !isAuthoring,
    resizable: settings.userCanChangeWidth || isAuthoring,
    sortable: settings.userCanSortColumns || isAuthoring,
    cellClass: getHeaderCellClass(settings),
    cellClassRules: {
      // allow overwriting styles on export
      groupRows: (params) => {
        if (params.value !== '') {
          return true;
        }
        return false;
      },

      'cell-wrap-text': () => {
        return settings.autoRowHeight ?? false;
      },
      'total-group-row-cell': (params) => params.node?.level === -1 || params.node?.rowPinned === 'top',
      'subtotal-group-row-cell': (params) =>
        params.node?.level !== -1 && params.node?.footer && params.node.group ? true : false,
    },
    headerClass: (params) => getHeaderClass(params, settings),

    cellRendererParams: () => getCellRendererParams(settings),
    //styles the rows that are part of the grouped columns
    cellStyle: (params) => {
      return getCellStyle(params, settings);
    },
  };
};

function getHeaderClass(params: HeaderClassParams, settings: Settings): string | string[] | undefined {
  if (!settings.showGroupMultiColumn) {
    return undefined;
  }

  const showRowGroup = params.column?.getColDef().showRowGroup;
  // Check if showRowGroup is a string, if not, use a default value
  const key = typeof showRowGroup === 'string' ? showRowGroup : '';
  const config = key ? settings.columnConfig[key] : undefined;

  if (!config) {
    return undefined;
  }

  const columnName = config.fieldName || key;
  return [
    `ag-header-${removeAgg(columnName).replace(/[^a-zA-Z0-9-_]/g, '')} `,
    `excel-header-${removeAgg(columnName).replace(/[^a-zA-Z0-9-_]/g, '')}`,
    config.headerAlignment ? 'ag-' + config.headerAlignment + '-aligned-header' : 'ag-left-aligned-header',
  ];
}

// Define a function to generate the cell renderer parameters
function getCellRendererParams(settings: Settings) {
  return {
    footerValueGetter: (params) => {
      const isRootLevel = params.node.level === -1;
      if (isRootLevel) {
        if (settings.showGroupMultiColumn) {
          const allDisplayedColumns = params.api.getAllDisplayedColumns();
          const firstColumn = allDisplayedColumns[0];
          if (params.column?.getColId() !== firstColumn.getColId()) {
            return ''; // only show total in first column
          }
        }
        return gridOptions?.localeText?.total || 'Total';
      }
      // with multi column grouping we want to show the sub total only in one column
      let subTotalValue = removeWhiteSpaces(params.node.key ?? params.value);

      if (
        settings.showGroupMultiColumn &&
        (!subTotalValue?.trim?.() || params.node.field !== params.column?.getColDef().showRowGroup)
      ) {
        return '';
      }
      return (gridOptions?.localeText?.subTotal || 'Sub total') + ' ' + subTotalValue;
    },
    suppressCount: settings.groupSuppressCount,
  };
}

// Define a function to style the rows that are part of the grouped columns
function getCellStyle(params: CellClassParams, settings: Settings) {
  const verticalAlignment =
    settings.autoRowHeight === true ? 'flex-start' : (settings.verticalAlignment ?? 'flex-start');
  const isGrandTotal = params.node?.level === -1 || params.node?.rowPinned === 'top';

  let displayProperties: CellStyle = {
    display: 'flex',
    alignItems: verticalAlignment === 'Bottom' ? 'flex-end' : verticalAlignment === 'Center' ? 'center' : 'flex-start',
  };

  let base: CellStyle = {
    ...displayProperties,
  };

  if (isGrandTotal) {
    return { ...base, fontWeight: 'bold' };
  }

  let config;
  if (params.node.group) {
    // this is a group row, we want to make sure it is on the same line
    base.whiteSpace = 'nowrap';
  }

  if (settings.showGroupMultiColumn) {
    // groups are under their own column
    const showRowGroup = params.column?.getColDef().showRowGroup;
    // Check if showRowGroup is a string, if not, use a default value
    const key = typeof showRowGroup === 'string' ? showRowGroup : '';
    config = key ? settings.columnConfig[key] : undefined;
    if (!config || !config?.color) {
      return base;
    }

    base.backgroundColor = config.color?.backgroundColor;
    base.fontColor = config.color?.fontColor;
    base.color = base.fontColor; // if using base as it is in css, then use color instead of fontColor

    if (params.node.field !== params.colDef.showRowGroup) {
      // this is not the column we are grouping on
      if (params.node.group && params.node.footer) {
        // this is the sub total row for the other column
        return null;
      }
      if (isValueEmpty(params.value)) return null;

      const column = config.color?.valueColumn;
      const value = column
        ? (params.api.getCellValue({ rowNode: params.node, colKey: column }) ?? params.value)
        : params.value;

      let background = 'map' in config.color && config.color.map?.[value];

      if (background) {
        base.backgroundColor = background;
      }
      return base;
    }
  } else {
    // groups are under the 'Group' Column
    if (params.node.field) {
      config = settings.columnConfig[params.node.field];
    } else if (params.node.parent && params.node.parent.field) {
      config = settings.columnConfig[params.node.parent.field];
    }

    if (!config || (!settings.showOpenedGroup && isValueEmpty(params.value))) {
      return displayProperties;
    }
    base.backgroundColor = config.color?.backgroundColor;
    base.fontColor = config.color?.fontColor;
    base.color = base.fontColor;
  }

  if (params.node.group && params.node.footer) {
    // sub total row
    if (settings.themeConfig.subtotalBackgroundColor) {
      base.backgroundColor = settings.themeConfig.subtotalBackgroundColor;
      base.fontColor = settings.themeConfig.subtotalTextColor;
    }
  }
  return createBackgroundStyler(config.color, base, config.colorCellBackground, true)(params);
}

function isValidDate() {
  throw new Error('Function not implemented.');
}

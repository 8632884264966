import { getSettings, Settings } from '@/settings';
import { ExpandedRowGroupsUtil } from '@/utils/ExpandedRowGroups';
import { RowGroupOpenedEvent } from 'ag-grid-community';

export function onRowGroupOpened(event: RowGroupOpenedEvent) {
  const settings = getSettings() as Settings;

  if (settings.autoExpandGroups) return;

  const context = event.context;
  if (!context) return;

  const hasExpanded = event.expanded;
  const rowTargeted = event.node.key;
  const groupName = event.node.field;
  const level = event.node.level;
  const rowId = event.node.id || 'ag-grid-row-group';

  const id = ExpandedRowGroupsUtil.generateId(rowTargeted, groupName, level, rowId);

  const expandedRowGroups = context.expandedRowGroups || {};
  if (hasExpanded) {
    const isPivotMode = event.api.isPivotMode();
    ExpandedRowGroupsUtil.add(expandedRowGroups, id, isPivotMode);
  } else {
    ExpandedRowGroupsUtil.remove(expandedRowGroups, id);
  }
}

import { Settings, getSettings } from '@/settings';
import { ColumnConfig, ContinuousColorConfig } from '@/types/settings';
import { EventBus } from '@/utils/event-bus';
import { debounce, immediateThenDebounce } from '@/utils/functional';
import { formatWithValueFormatter } from '@/utils/grid';
import { forEachSelectedNodes } from '@/utils/grid-selection';
import { getGridState } from '@/utils/grid-state';
import { findParameterAsync, getParametersAsync } from '@/utils/tableau';
import { useWBEStore } from '@/writeback/store';
import {
  BodyScrollEvent,
  CellEditingStartedEvent,
  CellRange,
  CellRangeParams,
  ColDef,
  ColGroupDef,
  ColumnPivotChangedEvent,
  ColumnPivotModeChangedEvent,
  ColumnResizedEvent,
  ColumnRowGroupChangedEvent,
  ColumnValueChangedEvent,
  ColumnVisibleEvent,
  FirstDataRenderedEvent,
  GetRowIdParams,
  GridOptions,
  GridReadyEvent,
  IAggFuncParams,
  IRowNode,
  RangeSelectionChangedEvent,
  RowDataUpdatedEvent,
  RowNode,
  RowPosition,
  SelectionChangedEvent,
} from 'ag-grid-community';
import { getColDefMap, gridApi } from '..';
import { compileCalculation } from '../calculation';
import {
  BarchartCellRenderer,
  EmojiCellRenderer,
  HTMLCellRenderer,
  ImageCellRenderer,
  MarkdownCellRenderer,
} from '../cellRenderers';
import { reloadCellRenderers } from '../mapping';
import { getContextMenuItems, getMainMenuItems } from '../menu';
import { isGroupOpenByDefault, onColumnRowGroupChanged, onRowGroupOpened } from './events';

const { primaryKeyColumn, bulkValueSetter, setSelectedCellRanges, cellEditingStarted } = useWBEStore.getState();

export const triggerHeaderHeight = debounce(calculateHeaderHeight, 200);
export const columnChangedHandler = debounce(triggerColumnChangedHandler, 200);

export const gridOptions: GridOptions = {
  enableRangeSelection: true,
  groupHeaderHeight: 28,
  rowSelection: 'multiple',
  groupSelectsFiltered: true,
  columnMenu: 'legacy',
  aggFuncs: {
    None: () => undefined,
    Calc: calcAggFunc,
    CountD: countDAggFunc,
    SelectiveAgg: selectiveAggregationAggFunc,
    List: listOfItemsFunc,
    PoT: percentOfTotalFunc,
  },
  components: {
    htmlCellRenderer: HTMLCellRenderer,
    mdCellRenderer: MarkdownCellRenderer,
    emojiCellRenderer: EmojiCellRenderer,
    imageCellRenderer: ImageCellRenderer,
    barChartCellRenderer: BarchartCellRenderer,
  },
  processCellForClipboard: formatWithValueFormatter,
  onCellEditingStarted(params: CellEditingStartedEvent) {
    cellEditingStarted(params); // save current selected state in case the user selects a cell and submits by clicking another cell
    setSelectedCell(params);
  },
  onCellEditingStopped: bulkValueSetter,
  onRowGroupOpened: onRowGroupOpened,
  isGroupOpenByDefault: isGroupOpenByDefault,
  onGridReady(params) {
    if (!params.api) return; // make ts happy

    let selectionChangedHandler = debounce(triggerSelectionChangedEvent, 250);
    params.api.addEventListener('rangeSelectionChanged', selectionChangedHandler);
    params.api.addEventListener('selectionChanged', selectionChangedHandler);

    params.api.addEventListener('rowClicked', (params) => {
      if (params?.node?.allChildrenCount && params.node.allChildrenCount > 0) {
        // this is only executed in group mode.
        var select = true;
        var clearSelection = true;
        if (params?.event?.ctrlKey) {
          select = !params.node.isSelected();
          clearSelection = false;
        }

        params.node.setSelected(select, clearSelection);
      }
    });
    params.api.addEventListener('rowDoubleClicked', (params) => {
      params.node.setSelected(true, false);
    });
    params.api.addEventListener('cellDoubleClicked', (params) => {
      // add the clicked cell to the range, dunno why it is not added when double clicking..
      let cellRange = {
        rowStartIndex: params.rowIndex,
        rowEndIndex: params.rowIndex,
        columnStart: params.column,
        columnEnd: params.column,
        columns: [params.column],
      } as CellRangeParams;
      params.api.addCellRange(cellRange);

      setSelectedCell(params);
    });
    params.api.addEventListener('cellClicked', (params) => {
      setSelectedCell(params);
    });
    const getTextFromCell = (cell: ColGroupDef) => {
      const children = Array.from(cell.children);
      const textNode = children.find((node: ColDef) => {
        if (typeof node.cellClass === 'string' || Array.isArray(node.cellClass)) {
          return node.cellClass.includes('ag-column-drop-vertical-cell-text');
        }
      });

      if (textNode && 'innerText' in textNode) {
        const text: string = textNode.innerText as string;
        const slicedText = text.match(/\((.)*\)/g)?.[0];
        return slicedText?.substr(1, slicedText.length - 2);
      }
      return '';
    };

    params.api.addEventListener('dragStopped', (params) => {
      const movedCell = params.target.parentElement;
      // todo: this is a hack, find a better way to check if the moved cell is in the values section
      if (movedCell && movedCell.ariaLabel?.endsWith('aggregation type')) {
        const colDefs = params.api.getColumns();
        const namedColdefs = colDefs?.map((column) => {
          let headerName = '';
          let headerValueGetter = column.getUserProvidedColDef()?.headerValueGetter;
          if (headerValueGetter && typeof headerValueGetter === 'function')
            headerName = headerValueGetter({
              column: { api: params.api, aggFunc: column.getAggFunc() }, //Are we using columnApi anywhere ?
            });
          return { column, headerName };
        });
        // Get all the cells in the values section and get the text from them
        const cells = Array.from(document.getElementsByClassName('ag-column-drop-vertical-cell'));
        // @ts-ignore
        const valueCells = cells.filter((cell) => cell.parentNode?.ariaLabel === 'Values');
        const names = valueCells.map(getTextFromCell);
        const orderedColumnDefs = names.map(
          (colName) => namedColdefs?.find((column) => column.headerName === colName)?.column
        );
        // only keep the last part of the name between the parentheses including the parentheses
        const movedCellName = getTextFromCell(movedCell);
        const movedColumnDef = namedColdefs?.find((column) => column.headerName === movedCellName)?.column;
        // remove the moved cell from the values to trigger a column change event and reload the columns
        params.api.removeValueColumns([movedColumnDef]);
        // add the moved cell again in the new order
        params.api.setValueColumns(orderedColumnDefs);
      }
    });

    let settings = getSettings() as Settings;
    if (settings.enableAutoAggregation) {
      const context = params.context;
      if (context?.updateParameterValues) {
        // the layout of the table has changed, we need to update the parameter values.
        // the layout changed using the mult-layout switcher feature.
        context.updateParameterValues = false;
        updateParameterValuesForAutoAggregation(params);
      }
      let columnChangedHandler = debounce(triggerColumnChangedHandler, 1000);

      params.api.addEventListener('columnVisible', (event: ColumnVisibleEvent) => {
        if (event.column?.isRowGroupActive()) {
          columnChangedHandler(event.column?.getColId(), true);
        } else if (!event.api.isPivotMode()) {
          if (event.columns && event.columns?.length > 1) {
            for (let i = 0; i < event.columns.length; i++) {
              triggerColumnChangedHandler(event.columns[i].getColId(), event.columns[i].isVisible());
            }
          } else {
            columnChangedHandler(event.column?.getColId(), event.column?.isVisible());
          }
        }
      });
      params.api.addEventListener('columnValueChanged', (event: ColumnValueChangedEvent) => {
        columnChangedHandler(event.column?.getColId(), event.column?.isValueActive());
      });
      params.api.addEventListener('columnPivotChanged', (event: ColumnPivotChangedEvent) => {
        columnChangedHandler(event.column?.getColId(), event.column?.isPivotActive());
      });

      params.api.addEventListener('columnPivotModeChanged', updateParameterValuesForAutoAggregation);
    }
    // for some reason displayedColumnsChanged doesn't capture resizes
    // columnResized triggers multiple times so we debounce the handler
    // here instead of where we register the actual handler...
    let stateChangedHandler = debounce(triggerStateChangedEvent, 1000);
    params.api.addEventListener('displayedColumnsChanged', stateChangedHandler);
    params.api.addEventListener('columnResized', stateChangedHandler);
    params.api.addEventListener('sortChanged', stateChangedHandler);
    params.api.addEventListener('filterChanged', stateChangedHandler);
    params.api.addEventListener('modelUpdated', stateChangedHandler);

    EventBus.triggerEvent('startFetching', params);

    // change the color of the WBE Button
    if (settings.showWbePanel) {
      const buttons = document.querySelectorAll('.ag-side-button-label');
      buttons.forEach((button) => {
        if (button.textContent.includes('InputTables')) {
          button.parentNode.style.backgroundColor = '#3B474F';
          button.parentNode.style.color = '#fff';
        }
      });
    }
  },
  onColumnRowGroupChanged: onColumnRowGroupChanged,
  onColumnResized: triggerHeaderHeight,
  onFirstDataRendered: (params) => {
    let settings = getSettings() as Settings;

    // Redraw rows to update row height based on cell content
    if (settings.autoRowHeight) {
      params.api.redrawRows();
      triggerHeaderHeight(params);
    }
  },
  onModelUpdated: (params) => {
    // keepUndoRedoStack: true if all we did is changed row height, data still the same, no need to clear the undo/redo stacks
    if (params.keepUndoRedoStack) return;
  },
  onFilterChanged: (params) => {
    let settings = getSettings() as Settings;

    if (Object.values(settings.columnConfig).some((col) => col?.quickTableCalc && col?.quickTableCalc !== 'none')) {
      params.api.redrawRows();
    }
  },
  onViewportChanged: (params) => {
    const settings = getSettings() as Settings;
    if (
      Object.values(settings.columnConfig).some((col) => {
        if (col?.color) {
          return col.color.type === 'continuous' && col.color.barChart;
        }
      })
    ) {
      params.api.redrawRows();
      updateCellrenderersWithCurrentColAggData(settings);
    }
  },
  onBodyScroll: triggerHeaderHeight,
  onRowDataUpdated,
  getContextMenuItems: getContextMenuItems,
  getMainMenuItems: getMainMenuItems,
};
if (primaryKeyColumn) {
  gridOptions.getRowId = (params: GetRowIdParams) => {
    return String(params.data[primaryKeyColumn] ?? params.data.index);
  };
}

// when range selection is not enabled, save the current selection so we can use it in store.writeData
function setSelectedCell(params) {
  if (!params.api.getGridOption('enableRangeSelection')) {
    setSelectedCellRanges([
      {
        startRow: {
          rowIndex: params.rowIndex,
          rowPinned: null,
        } as RowPosition,
        endRow: {
          rowIndex: params.rowIndex,
          rowPinned: null,
        } as RowPosition,
        columns: [params.column],
        startColumn: params.column,
      } as CellRange,
    ]);
  }
}

function calculateHeaderHeight(
  params:
    | ColumnResizedEvent
    | BodyScrollEvent
    | ColumnRowGroupChangedEvent
    | FirstDataRenderedEvent
    | RowDataUpdatedEvent
) {
  let tallestHeader = 0;

  let headers = Array.from(document.querySelectorAll('.ag-header-cell-text'));

  headers.forEach((el) => {
    tallestHeader = tallestHeader > el.clientHeight ? tallestHeader : el.clientHeight;
  });

  const headerPadding = 20;
  params.api.setGridOption('headerHeight', tallestHeader + headerPadding);
}

function triggerSelectionChangedEvent(event: RangeSelectionChangedEvent | SelectionChangedEvent) {
  if (!event) return; // make ts happy

  let selectedRows = new Set();
  forEachSelectedNodes(event.api, (rowNode: Set<RowNode>) => {
    selectedRows.add(rowNode);
  });

  EventBus.triggerEvent('selectionChanged', Array.from(selectedRows));
}

function triggerStateChangedEvent() {
  EventBus.triggerEvent('stateChanged');
}

async function triggerColumnChangedHandler(colId?: string, newValue?: any) {
  const pm = await findParameterAsync(colId);
  if (pm && typeof pm !== 'boolean') {
    if (pm.currentValue.value === String(newValue)) return;
    let value = JSON.stringify(newValue);

    if (pm?.allowableValues?.type === 'list') {
      value = pm.allowableValues.allowableValues?.find((x) => x.nativeValue === newValue)?.formattedValue ?? '';
    }
    if (value) {
      await pm.changeValueAsync(value);
    }
  }
}

async function updateParameterValuesForAutoAggregation(params: ColumnPivotModeChangedEvent | GridReadyEvent) {
  const parameters = await getParametersAsync();
  const parameterNames = parameters?.map((parameter) => parameter.name);
  const columnColIds = params.api.getColumns()?.map((column) => column.getColId());
  const parametersContainingColumn = parameterNames?.filter((parameter) => columnColIds?.includes(parameter));

  if (!parametersContainingColumn?.length) return;

  for (const colId of parametersContainingColumn) {
    const column = params.api.getColumn(colId);
    const columnVisibility =
      column?.isRowGroupActive() ||
      (!params.api.isPivotMode() ? column?.isVisible() : column?.isPivotActive() || column?.isValueActive());
    await triggerColumnChangedHandler(colId, columnVisibility);
  }
}

interface AggregationData {
  min: number;
  max: number;
}

const updateCellrenderersWithCurrentColAggData = debounce(function (settings: Settings) {
  const { columnConfig } = settings;
  if (!columnConfig) return;

  let columnsWithAutoBarChart = false;

  Object.keys(columnConfig).forEach((columnKey) => {
    const config = columnConfig[columnKey] as ColumnConfig;
    if (!config) return false;
    if (config?.color && 'type' in config.color && config.color.type === 'continuous' && config.color.barChart) {
      const colorConfig = config.color as ContinuousColorConfig;
      if (!columnsWithAutoBarChart) columnsWithAutoBarChart = !!(colorConfig.autoMax || colorConfig.autoMin);
    }
  });

  if (!columnsWithAutoBarChart) return;

  const colAggData: { [key: string]: AggregationData } = {}; //This should be a number

  const forEachNodeFunc = (node: IRowNode) => {
    // Potential feature: Make this configurable
    if (!node.displayed) return;

    const row = node.aggData ?? node.data;

    for (const key in row) {
      const colorConfig = columnConfig[key]?.color;
      let value = gridApi?.getCellValue({ rowNode: node, colKey: key, useFormatter: false });

      // If the value is an object, this is from a calculated column and we need to get the value from it
      if (value && typeof value === 'object' && 'value' in value) value = value.value;

      if (typeof value === 'number') {
        // @ts-expect-error - Only do this if the setting is enabled
        if (colorConfig?.barchartUseAbsoluteValues) value = Math.abs(value);

        // Get the min and max values per column
        if (colAggData.hasOwnProperty(key)) {
          colAggData[key].max = Math.max(colAggData[key].max, value);
          colAggData[key].min = Math.min(colAggData[key].min, value);
        } else {
          colAggData[key] = { min: value, max: value };
        }
      }
    }
  };

  // Potential feature: Make this configurable to maybe include grand total row
  // this can be done with gridApi?.getRenderedNodes()
  gridApi?.forEachNode(forEachNodeFunc); // <- this one inlcudes all hidden nodes but no total row nodes

  gridOptions.context = {
    ...gridOptions.context,
    colAggData,
  };

  reloadCellRenderers(gridOptions);
}, 10);

function calcAggFunc(params: IAggFuncParams) {
  let { context, values } = params;
  let allDataObjects;

  function collectRowNodeData(rowNodes, result = []) {
    if (
      params.rowNode.allLeafChildren &&
      params.rowNode.childrenAfterFilter &&
      params.rowNode.childrenAfterFilter.length === params.rowNode.allLeafChildren.length
    ) {
      return params.rowNode?.allLeafChildren?.map((rowNode) => rowNode.data) || [];
    }
    rowNodes.forEach((rowNode) => {
      if (rowNode.data) {
        result.push(rowNode.data); // Add rowNode.data if it exists
      } else if (rowNode.childrenAfterFilter && rowNode.childrenAfterFilter.length > 0) {
        collectRowNodeData(rowNode.childrenAfterFilter, result); // Recurse into children
      }
    });
    return result;
  }

  allDataObjects = collectRowNodeData(params.rowNode.childrenAfterFilter);

  // Apply filter only if Pivot Mode is enabled
  if (params.api.isPivotMode()) {
    const cols = params.api.getPivotColumns().map((col) => col.getColId());
    const colVals = params.pivotResultColumn?.getColDef().pivotKeys || [];
    allDataObjects = allDataObjects.filter((dataObj) => cols.every((col, i) => dataObj[col] === colVals[i]));
  }

  if (allDataObjects && allDataObjects.length > 0) {
    values = allDataObjects;
  }
  // HACK: for grouping, the aggData is either undefined or not up to date in most cases
  // so we rely on the valueGetter which always contains upto date aggData
  // pivot calculation depend on values
  if (values.length === 0) return undefined;

  const tableauColumns = context.tableauColumns;
  const column = tableauColumns.find((column: { index: string }) => column?.index === params.column.getColId());
  let calc = compileCalculation(column, tableauColumns);
  if (!calc) return undefined;
  // TODO: calculate only fields that are used in the calculation
  // const usedFields = getUsedFields(column.calculation);

  let mergedValues: { [key: string]: any } = {};

  const columnDefMap = getColDefMap(params.api);
  if (Array.isArray(values) && values.length > 0 && values[0] !== undefined) {
    for (const key of Object.keys(values[0])) {
      if (!column?.calculation?.includes(key)) continue;
      const aggFunc = columnDefMap[key]?.aggFunc || columnDefMap[key]?.defaultAggFunc;
      if (aggFunc === 'count') {
        mergedValues[key] = values.length;
        continue;
      } else if (aggFunc === 'CountD') {
        mergedValues[key] = new Set(values.map((obj) => obj[key])).size;
        continue;
      } else if (aggFunc === 'first') {
        mergedValues[key] = values.at(0);
        continue;
      } else if (aggFunc === 'last') {
        mergedValues[key] = values.at(-1);
        continue;
      }

      for (let i = 0; i < values.length; i++) {
        let loopValue = values[i][key];
        if (loopValue == null || loopValue === '') continue;
        if (loopValue === '%null%') continue;

        // if value is a string or an object then continue
        // objects are calculation columns, string don't need to be considered in the calculation

        if (typeof loopValue === 'number') {
          if (key in mergedValues) {
            // Apply the aggregation logic
            switch (aggFunc) {
              case 'sum':
                mergedValues[key] += loopValue;
                break;
              case 'min':
                mergedValues[key] = Math.min(mergedValues[key], loopValue);
                break;
              case 'max':
                mergedValues[key] = Math.max(mergedValues[key], loopValue);
                break;
              case 'avg':
                mergedValues[key] = mergedValues[key] + loopValue;
                if (i === values.length - 1) {
                  mergedValues[key] = mergedValues[key] / values.length;
                }
                break;
              case 'SelectiveAgg':
                if (values.length > 0) {
                  let columnObj = params.api.getColumnDef(key) || columnDefMap[key];

                  if (!columnObj || !columnObj.colId) {
                    break;
                  }

                  const currentGroupColumnField = params.rowNode?.data?.[key] || null;
                  const context = params.api.getContext ? params.api.getContext() : params.context;
                  const pivotResultColumn = params.pivotResultColumn || null; // Ensure we pass this

                  const selectiveAggParams = {
                    ...params,
                    values: values.map((v) => v[key]),
                    column: columnObj,
                    context: context,
                    columnDefMap: columnDefMap,
                    currentGroupColumnField: currentGroupColumnField,
                    pivotResultColumn: pivotResultColumn,
                  };
                  mergedValues[key] = selectiveAggregationAggFunc(selectiveAggParams);
                }
                break;

              default:
                mergedValues[key] += loopValue;
            }
          } else {
            mergedValues[key] = loopValue;
          }
        }

        // only for string values there will be support for count and countD
        // TODO remove the aggFunc check so we always have a default for dimensions that do not have an aggFunc yet?

        if (typeof loopValue === 'string') {
          if (key in mergedValues) {
            // Apply the aggregation logic
            switch (aggFunc) {
              case 'List':
                if (!mergedValues[key].includes(loopValue)) {
                  mergedValues[key].push(loopValue);
                }
                break;
              default:
                mergedValues[key].push(loopValue);
            }
          } else {
            mergedValues[key] = [loopValue];
          }
        }
      }
    }
  }
  for (let key in mergedValues) {
    if (mergedValues[key] instanceof Array) {
      mergedValues[key] = mergedValues[key].length;
    }
  }

  const calculationResult = calc(mergedValues);
  mergedValues.value = calculationResult;
  mergedValues.valueOf = () => calculationResult;
  mergedValues.toString = () => (calculationResult ? `${calculationResult}` : '');
  return mergedValues;
}

function percentOfTotalFunc(params: IAggFuncParams): any {
  const underlyingValues = getUnderlyingValues(params.values);
  const total: number = underlyingValues.reduce((a, b) => a + b, 0);
  return {
    value: total,
    toString: () => total.toString(),
    valueOf: () => total,
    underlyingValues: total,
  };
}

function countDAggFunc(params: IAggFuncParams): any {
  const underlyingValues = getUnderlyingValues(params.values);
  const uniqueValues = new Set(underlyingValues);
  return {
    value: uniqueValues.size,
    toString: () => uniqueValues.size.toString(),
    valueOf: () => uniqueValues.size,
    underlyingValues: Array.from(uniqueValues.values()),
  };
}

// flat the array with infinite depth and remove undefined and null values from the array and return the underlying values
const getUnderlyingValues = (values: any[]) => values.map((value) => getUnderlyingValue(value)).flat(Infinity);

// To get the underlying values from the cell value
// if the cell value is an array then it will return the underlying values of the array
// if the cell value is an object then it will return the underlying values of the object
const getUnderlyingValue = (value: any): any[] => {
  if (value === undefined || value === null) {
    return [];
  }

  if (Array.isArray(value)) {
    return getUnderlyingValues(value);
  }

  if (value.hasOwnProperty('underlyingValues')) {
    return value.underlyingValues;
  }
  return value;
};

function listOfItemsFunc(params: IAggFuncParams) {
  // always a grouped row from here on
  const currentAggColumn = params.column.getColId();

  let set = new Set();
  function getChildrenNodeFinalValue(children: IRowNode[] | null) {
    children?.forEach((child, index) => {
      set.add(child.data[currentAggColumn]);
    });
    return Array.from(set).sort().join(', ');
  }
  const children = params.rowNode.allLeafChildren;
  return getChildrenNodeFinalValue(children);
}

function selectiveAggregationAggFunc(params: IAggFuncParams) {
  if (params.values.length === 1) return params.values[0];
  let cols, colVals;
  if (params.api.isPivotMode()) {
    cols = params.api.getPivotColumns().map((col) => col.getColId());
    colVals = params.pivotResultColumn?.getColDef().pivotKeys || [];
  }
  const columnMappingToFlag = params.context.selectiveAggregationMap;
  const currentAggColumn = params.column.getColId();
  const currentGroupColumnField = params.rowNode.field;
  const currentGroupColumnFlag =
    currentGroupColumnField && columnMappingToFlag ? columnMappingToFlag[currentGroupColumnField] : '';
  function getLeafChildValue(node: IRowNode) {
    const flagIndicator = currentGroupColumnFlag ? (node.data?.[currentGroupColumnFlag] ?? '1') : '1';
    return flagIndicator === '1' ? node.data[currentAggColumn] : 0;
  }

  function getChildrenNodeFinalValue(children: IRowNode[] | null) {
    let finalValue = 0;
    children?.forEach((child, index) => {
      if (typeof getLeafChildValue(child) === 'number') {
        if (params.api.isPivotMode()) {
          if (!cols.some((col, i) => child.data[col] !== colVals[i])) {
            finalValue += getLeafChildValue(child);
          }
        } else {
          finalValue += getLeafChildValue(child);
        }
      }
    });
    return finalValue;
  }

  // MARK: This is a special case.
  // it only applies when a group row has 2 children, where
  // 1. one child is a leaf node (no children)
  // 2. the other child is a group node (has children) and all the children have a flag value of 0
  // in this case, we should return the value in the leaf node
  const rowNode = params.rowNode;
  if (
    rowNode.childrenAfterFilter?.length === 2 &&
    !rowNode.childrenAfterFilter[0].group &&
    rowNode.childrenAfterFilter[1].group
  ) {
    // there are 2 child nodes, one is a leaf and the other is a group
    // if the children of the group have all 0's in their flag value, then we should return the value in the leaf node
    const leafNode = rowNode.childrenAfterFilter[0];
    const groupNode = rowNode.childrenAfterFilter[1];
    const groupNodeLeafValue = getChildrenNodeFinalValue(groupNode.allLeafChildren);
    if (groupNodeLeafValue === 0) {
      return leafNode.data[currentAggColumn];
    }
  }

  const children = params.rowNode.allLeafChildren;
  return getChildrenNodeFinalValue(children);
}

function onRowDataUpdated(event: RowDataUpdatedEvent) {
  // const settings = getSettings() as Settings;
  // if (settings.autoExpandGroups) {
  //   expandAllButLastNGroups(event.api, settings.expandAllButLastNGroups);
  // }

  triggerHeaderHeight(event);
}

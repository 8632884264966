import { ExpandedRowGroups } from '@/types/grid';

const DELIMITER = ':::';

export const ExpandedRowGroupsUtil = {
  generateId(key: string | null, field: string | null, level: number, rowId: string): string {
    return `${key}${DELIMITER}${field}${DELIMITER}${level}${DELIMITER}${rowId}`;
  },

  parseId(id: string): { key: string; field: string; level: string; rowId: string } {
    const parts = id.split(DELIMITER);
    if (parts.length !== 4) {
      throw new Error('Invalid id format');
    }
    const [key, field, level, rowId] = parts;
    return { key, field, level, rowId };
  },

  getKey(id: string): string {
    return this.parseId(id).key;
  },

  getField(id: string): string {
    return this.parseId(id).field;
  },

  getLevel(id: string): string {
    return this.parseId(id).level;
  },

  getRowId(id: string): string {
    return this.parseId(id).rowId;
  },

  exists(expandedRowGroups: ExpandedRowGroups = {}, id: string): boolean {
    return Object.prototype.hasOwnProperty.call(expandedRowGroups, id);
  },

  getValue(expandedRowGroups: ExpandedRowGroups = {}, id: string): boolean | undefined {
    return expandedRowGroups[id];
  },

  add(expandedRowGroups: ExpandedRowGroups = {}, id: string, isPivot: boolean = true): void {
    expandedRowGroups[id] = isPivot;
  },

  remove(expandedRowGroups: ExpandedRowGroups = {}, id: string): void {
    delete expandedRowGroups[id];
  },

  removeAllFields(expandedRowGroups: ExpandedRowGroups = {}, field: string): void {
    for (const id in expandedRowGroups) {
      if (this.getField(id) === field) {
        delete expandedRowGroups[id];
      }
    }
  },
};

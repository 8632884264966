import { getSettings, Settings } from '@/settings';
import { ExpandedRowGroupsUtil } from '@/utils/ExpandedRowGroups';
import { IsGroupOpenByDefaultParams } from 'ag-grid-community';

export function isGroupOpenByDefault(params: IsGroupOpenByDefaultParams) {
  const settings = getSettings() as Settings;
  const api = params.api;

  if (settings.autoExpandGroups) {
    const groupCount = api.getRowGroupColumns().length;
    const nGroups = settings.expandAllButLastNGroups;
    return params.level < groupCount - nGroups;
  }

  const context = params.context;
  if (!context) return false;

  const expandedRowGroups = context?.expandedRowGroups || {};

  const key = params.key;
  const field = params.field;
  const level = params.level;
  const rowId = params.rowNode.id || 'ag-grid-row-group';
  const id = ExpandedRowGroupsUtil.generateId(key, field, level, rowId);

  return ExpandedRowGroupsUtil.exists(expandedRowGroups, id);
}

import { GridApi } from 'ag-grid-community';

export let gridApi: GridApi | undefined;

var colDefMap;

export function setGridApi(api: GridApi | undefined) {
  gridApi = api;

  getColDefMap(api);
}

export function getColDefMap(api) {
  if (!colDefMap) {
    colDefMap = api?.getColumnDefs()?.reduce((old, column) => {
      old[column.colId] = column;
      return old;
    }, {});
    setTimeout(() => {
      colDefMap = undefined;
    }, 500);
  }
  return colDefMap;
}
